import { OmcModel } from './omcUtil.mjs';
import getIdentifier from './getIdentifier.mjs';

/**
 * Fetch either a set of related entities or a single entity,
 * returns the response as an OMC object, or an array of OMC objects based on the input
 *
 * @param omc {object | [object]} - The OMC object or array of OMC objects
 * @param currentProject {string} - Name of the current project
 * @param accessToken {string} - The access token for the request
 * @returns {Promise<Awaited<unknown>[]|Promise<*|null>|*>}
 */
async function getEntities(omc, currentProject, accessToken) {
    return Array.isArray(omc)
        ? (await Promise.all(omc.map((ent) => getIdentifier({ params: { ...ent.identifier[0], currentProject }, accessToken }))))
            .filter((d) => d && d.identifier)
        : getIdentifier({ params: { ...omc.identifier[0], currentProject }, accessToken });
}

/**
 * Dereference sets of entities in an OMC instance, returns an object with the keys and their dereferenced entities
 *
 * @param omc {object} - The Context property of the parent entity.
 * @param relatedProps {string[]} - The keys to dereference
 * @param currentProject {string} - Name of the current project
 * @param accessToken {string} - The access token for the request
 * @returns {Promise<*>}
 */
async function fetchRelatedEntities(omc, relatedProps, currentProject, accessToken) {
    const fTestPromise = relatedProps.map((key) => getEntities(omc[key], currentProject, accessToken));
    const results = await Promise.all(fTestPromise);
    return relatedProps.reduce((obj, eName, i) => ({ ...obj, [eName]: results[i] }), {});
}

/**
 * Fetch the referenced entities in a Context of an OMC instance
 *
 * @param omcContext {object[]} - The Context property of the parent entity.
 * @param currentProject {string} - Name of the current project
 * @param accessToken {string} - The access token for the request
 * @returns {Promise<*>}
 */
async function cxtRelatedEntities(omcContext, currentProject, accessToken) {
    if (!omcContext) return null; // If there is no Context

    // Dereference each edge and each set of related entity types
    const edgePromise = omcContext.map(async (cxt) => {
        const relations = cxt.contextEdges(); // The keys for the edges
        const relPromise = relations.map((key) => {
            const entTypes = (Object.keys(cxt[key])); // The related entity types
            return fetchRelatedEntities(cxt[key], entTypes, currentProject, accessToken)
        });
        const results = await Promise.all(relPromise);
        return relations.reduce((obj, rKey, i) => ({ ...obj, [rKey]: results[i] }), {});
    });
    const edges = await Promise.all(edgePromise); // The related entities for each Context
    // Merge the related entities with the Context (cannot use spread, as prototypes from OmcModel should be preserved)
    return omcContext.map((cxt, i) => {
        const updatedCxt = { ...cxt };
        Object.keys(edges[i]).forEach((eKey) => { updatedCxt[eKey] = edges[i][eKey]; });
        return OmcModel(updatedCxt);
    });
}

/**
 * Fetch the intrinsic entities for an OMC instance
 *
 * @param omc {object}} - The OMC object or array of OMC objects
 * @param currentProject {string} - Name of the current project
 * @param accessToken {string} - The access token for the request
 * @returns {Promise<*>}
 */
async function intrinsicEntities(omc, currentProject, accessToken) {
    const relatedEnt = omc.intrinsicEdges(); // Keys for intrinsic properties that have values
    return fetchRelatedEntities(omc, relatedEnt, currentProject, accessToken);
}

/**
 * Resolve all references for an OMC instance
 *
 * @param omc {object} - The OMC instance
 * @param currentProject {string} - Name of the current project
 * @param accessToken {string} - The access token for request
 * @returns {Promise<*>}
 */
async function fetchOmc(omc, currentProject, accessToken) {
    const intrinsicEdges = await intrinsicEntities(omc, currentProject, accessToken);
    const contextEdges = await cxtRelatedEntities(intrinsicEdges?.Context, currentProject, accessToken); // Resolve the Context entities
    return OmcModel({
        ...omc,
        ...intrinsicEdges,
        ...{ Context: contextEdges },
    });
}

export default fetchOmc;
