import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import {
    Box,
    Button,
    Stack,
} from '@chakra-ui/react';
import { Toaster, toaster } from '../Components/ui/toaster';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const resetPath = '/api/admin/reset';
const hpaTestPath = '/api/admin/testcharacter';
const projectsPath = '/api/admin/project';

const fetchReset = (async (accessToken) => {
    const url = labkoatApiUrl + resetPath;
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return fetch(url, options);
});

const fetchTestHpa = (async (accessToken) => {
    const url = labkoatApiUrl + hpaTestPath;
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return fetch(url, options);
});

const fetchProjects = (async (accessToken) => {
    const url = labkoatApiUrl + projectsPath;
    console.log(url);
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return fetch(url, options);
});

function Admin() {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const [isReseting, setIsReseting] = useState(false);
    const [isTesting, setIsTesting] = useState(false);

    useEffect(() => {
        if (!accessToken) return;
        const fetchProjectDetails = async () => {
            const projectDetails = await fetchProjects(accessToken);
            console.log(projectDetails.status);
            if (projectDetails.status === 200) {
                const p = await projectDetails.json();
                console.log(p);
            }
        };
        fetchProjectDetails();
    }, [oktaAuth]);

    const handleReset = (async () => {
        setIsReseting(true);
        try {
            const res = await fetchReset(accessToken);
            setIsReseting(false);
            const resJson = await res.json();
            console.log(resJson);
            const toast = {
                title: 'Reset Successful',
                description: 'Yamdu has been reset',
                type: 'success',
                duration: 5000,
                isClosable: true,
            };
            if (res.status !== 200) { // Check for an error
                toast.title = 'Error';
                toast.description = resJson.error.details;
                toast.type = 'error';
            }
            toaster.create(toast);
        } catch (err) {
            console.log(err);
        }
    });

    const handleHpaTest = (async () => {
        setIsTesting(true);
        try {
            const res = await fetchTestHpa(accessToken);
            setIsTesting(false);
            const toast = {
                title: 'Test sent',
                description: 'Character update sent to tray IO',
                type: 'success',
                duration: 5000,
                isClosable: true,
            };
            if (res.status !== 200) { // Check for an error
                const resJson = await res.json();
                toast.title = 'Error';
                toast.description = resJson.error.details;
                toast.type = 'error';
            }
            toaster.create(toast);
        } catch (err) {
            console.log(err);
        }
    });

    return (
        <>
            <Toaster />
            <Stack>
                <Box m="3">
                    <Button
                        loading={isReseting}
                        colorPalette="blue"
                        variant="surface"
                        onClick={handleReset}
                    >
                        Reset Yamdu
                    </Button>
                </Box>
                <Box m="3">
                    <Button
                        loading={isTesting}
                        colorPalette="blue"
                        variant="surface"
                        onClick={handleHpaTest}
                    >
                        Test TrayIO
                    </Button>
                </Box>
            </Stack>
        </>
    );
}

export default Admin;
