/*
Request a single OMC entity from the LabKoat API using the provided parameters.
The function returns the OMC entity as a JavaScript object.
*/

import { OmcModel } from './omcUtil.mjs';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const apiPath = '/api/omc/v1/entitytype';

/**
 * Request a single OMC entity from the LabKoat API using the provided parameters.
 * @param params
 * @param params.displayMessage {function} - The function to toast messages in UI
 * @param params.identifierScope {string} - The scope of the identifier
 * @param params.identifierValue {string} - The value of the identifier
 * @param params.currentProject {string} - The current project identifier
 * @param accessToken {string} - The access token for the request
 * @returns {Promise<(*)|null>}
 */

export default async function getEntityType({ params, accessToken }) {
    const {
        displayMessage = () => {},
        currentProject: project,
        entityType,
        ...body
    } = params;

    // Display an array of messages
    const iterateMsg = ((msgs, status) => {
        msgs.forEach((msg) => { displayMessage({ title: msg.title, description: msg.details, status }); });
    });

    const url = `${labkoatApiUrl}${apiPath}/${entityType}?project=${project}`;
    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
    };
    console.log(url);
    console.log(options);

    try {
        const res = await fetch(url, options);
        const payload = await res.json();

        if (payload.warnings) iterateMsg(payload.warnings, 'warning'); // Display any warnings
        if (payload.errors) iterateMsg(payload.errors, 'error'); // Display any errors

        return OmcModel(payload.data[0]);
    } catch (err) {
        console.log(err);
        return null;
    }
}
