import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    Box,
    Center,
    Flex,
    Input,
    Button,
    IconButton,
    createListCollection,
} from '@chakra-ui/react';
import { UpDownIcon } from '@chakra-ui/icons';

import { Field } from '../../ui/field';
import {
    SelectRoot,
    SelectItem,
    SelectValueText,
    SelectTrigger,
    SelectContent,
    SelectLabel,
} from '../../ui/select';

import { nodeParams } from './omcDefaults.mjs';

function Dropdown({
    items,
    control,
    title,
    name
}) {
    // Each of the projects that can be selected, create items for dropdown selection
    const dropdownItems = createListCollection({ items });
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={items[0].value}
            render={({ field }) => (
                <SelectRoot
                    collection={dropdownItems}
                    variant="outline"
                    name={field.name}
                    value={field.value}
                    onValueChange={(e) => {
                        console.log(e.value);
                        field.onChange(e.value);
                    }}
                >
                    <SelectLabel>
                        {title}
                    </SelectLabel>
                    <SelectTrigger>
                        <SelectValueText placeholder={field.value} />
                    </SelectTrigger>
                    <SelectContent>
                        {dropdownItems.items.map((pr) => (
                            <SelectItem item={pr} key={pr.value}>
                                {pr.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </SelectRoot>
            )}
        />
    );
}

function ProjectSelect({
    projects,
    currentProject,
    handleProject,
}) {
    const [value, setValue] = useState([currentProject]);

    // Each of the projects that can be selected, create items for dropdown selection
    const options = Object.keys(projects).map((v) => ({
        value: v,
        label: projects[v].label,
    }));
    const selectItems = createListCollection({ items: options });

    return (
        <Box w="100%">
            <SelectRoot
                collection={selectItems}
                variant="outline"
                value={value}
                onValueChange={(e) => {
                    console.log(e.value);
                    setValue(e.value);
                    handleProject(e.value[0]);
                }}
            >
                <SelectLabel>
                    Project Test
                </SelectLabel>
                <SelectTrigger>
                    <SelectValueText placeholder={currentProject} />
                </SelectTrigger>
                <SelectContent>
                    {selectItems.items.map((pr) => (
                        <SelectItem item={pr} key={pr.value}>
                            {pr.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </SelectRoot>
        </Box>
    );
}

function IdentifierQuery({
    identifierScope,
    resetRoot,
    currentProject,
    displayMessage,
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = handleSubmit(async (values) => {
        const valid = await resetRoot({
            displayMessage,
            currentProject,
            identifierScope: values.identifierScope.trim(),
            identifierValue: values.identifierValue.trim(),
        });
    });

    return (
        <form onSubmit={onSubmit}>
            <Flex>
                <Box w="30%" pl={2} pr={2}>
                    <Field
                        label="Identifier Scope"
                        invalid={!!errors.identifierScope}
                        errorText={errors.identifierScope?.message}
                    >
                        <Input
                            {...register('identifierScope', { required: 'Scope is required' })}
                            value={identifierScope}
                        />
                    </Field>
                </Box>
                <Box w="40%" pl={2} pr={2}>
                    <Field
                        label="Identifier Value"
                        invalid={!!errors.identifierValue}
                        errorText={errors.identifierValue?.message}
                    >
                        <Input
                            {...register('identifierValue', { required: 'Value is required' })}
                        />
                    </Field>
                </Box>
                <Box w="33%" pl={2} pr={2} alignSelf="flex-end">
                    <Button
                        colorPalette="teal"
                        type="submit"
                        float="right"
                    >
                        Submit
                    </Button>
                </Box>
            </Flex>
        </form>
    );
}

function PropertyQuery({
    entities = [], // Values for the entity dropdown
    fields = [], // Values for the field dropdown
    currentProject,
    displayMessage,
    resetRoot,
}) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    // const onSubmit = handleSubmit((data) => console.log(data));
    const onSubmit = handleSubmit(async (data) => {
        const valid = await resetRoot({
            displayMessage,
            currentProject,
            entityType: data.entityType[0],
            [data.propertyName]: data.propertyValue,
        });
    });

    return (
        <form onSubmit={onSubmit}>
            <Flex>
                <Box w="25%" pl={2} pr={2}>
                    <Dropdown
                        control={control}
                        name="entityType"
                        title="Entity Type"
                        items={entities}
                    />
                </Box>
                <Box w="25%" pl={2} pr={2}>
                    <Dropdown
                        control={control}
                        name="propertyName"
                        title="Property Name"
                        items={fields}
                    />
                </Box>
                <Box w="25%" pl={2} pr={2}>
                    <Field
                        label="Property Value"
                        invalid={!!errors.identifierValue}
                        errorText={errors.identifierValue?.message}
                    >
                        <Input
                            {...register('propertyValue', { required: 'Value is required' })}
                        />
                    </Field>
                </Box>
                <Box w="25%" pl={2} pr={2} alignSelf="flex-end">
                    <Button
                        colorPalette="teal"
                        type="submit"
                        float="right"
                    >
                        Submit
                    </Button>
                </Box>
            </Flex>
        </form>
    );
}

function OmcTreeQueryBar({
    currentProject,
    projects,
    handleProjectChange,
    resetRoot,
    displayMessage,
}) {
    const [queryType, setQueryType] = useState('identifier');
    const entities = Object.keys(nodeParams)
        .filter((e) => (nodeParams[e].query.allow))
        .map((e) => ({
            value: e,
            label: e,
        }));
    const fields = [
        { value: 'name', label: 'Name' },
        { value: 'description', label: 'Description' },
    ];

    function handleQueryChange() {
        const newQueryType = queryType === 'identifier' ? 'property' : 'identifier';
        setQueryType(newQueryType);
    }

    return (
        <Box>
            <Flex m={2}>
                <Box w="20%" pl={2} pr={2}>
                    <ProjectSelect
                        currentProject={currentProject}
                        handleProject={(e) => handleProjectChange(e)}
                        projects={projects}
                    />
                </Box>
                <Box w="80%">
                    <Flex>
                        <Center>
                            <IconButton
                                variant="subtle"
                                colorPalette="blue"
                                w="5%"
                                alignSelf="flex-end"
                                aria-label="Select Query"
                                onClick={(e) => handleQueryChange(e)}
                            >
                                <UpDownIcon />
                            </IconButton>
                        </Center>
                        <Box w="95%">
                            {queryType === 'identifier'
                                ? (
                                    <IdentifierQuery
                                        currentProject={currentProject}
                                        identifierScope={projects[currentProject].identifierScope}
                                        resetRoot={resetRoot}
                                        displayMessage={displayMessage}
                                    />
                                )
                                : (
                                    <PropertyQuery
                                        entities={entities}
                                        fields={fields}
                                        currentProject={currentProject}
                                        resetRoot={resetRoot}
                                        displayMessage={displayMessage}
                                    />
                                )}
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

export default OmcTreeQueryBar;
