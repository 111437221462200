/**
 * SVG Container for the D3 graph
 */

import React, { useState, useEffect, createRef, useCallback } from 'react';

import {
    Box,
    Flex,
} from '@chakra-ui/react';

import D3ZoomWrapper from '../D3ZoomWrapper';
import OmcTreeSideBar from './OmcTreeSideBar';
import OmcTreeDiagram from './OmcTreeDiagram';
import omcTree from '../../../services/omcTree.mjs';
import OmcTreeQueryBar from './OmcTreeQueryBar';

import useWindowInnerSize from '../../../hooks/useWindowInnerSize.mjs';
import { Toaster, toaster } from '../../ui/toaster';

const treeId = 'omcExploreTree';
const graphTableRatio = 1; // The ratio of size for the top display to the bottom

function OmcTreeContainer({
    omcData,
    currentProject,
    projects,
    handleProjectChange,
    resetRoot,
    accessToken,
    resetZoom, // Reset the zoom and center the tree
}) {
    const identifierScope = projects[currentProject].primaryScope;

    const containerRef = createRef(null); // Reference for container that holds the chart and table
    const { innerWidth, innerHeight } = useWindowInnerSize(); // Dimensions of container
    const [d3ContainerSize, setD3ContainerSize] = useState({ width: 0, height: 0 }); // D3 container dimensions
    const [tableContainerSize, setTableContainerSize] = useState({ width: 0, height: 0 }); // Table container dimensions

    const [chartData, setChartData] = useState(null);
    const [sideBarOmc, setSideBarOmc] = useState(null);

    // Set the sizes for the chart and table containers
    useEffect(() => {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const cHeight = (innerHeight - containerTop); // Padding and margin adjustments
        setD3ContainerSize({ width: innerWidth, height: cHeight * graphTableRatio });
        setTableContainerSize({ width: innerWidth, height: cHeight * (1 - graphTableRatio) });
    }, [innerWidth, innerHeight, currentProject, resetZoom]);

    useEffect(() => {
        if (!omcData) return;
        console.log(omcData);
        omcTree(omcData, identifierScope)
            .then((data) => {
                console.log(data);
                setChartData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [omcData]);

    // Use a Toast to display messages to the user
    const displayMessage = useCallback((message) => {
        toaster.create({
            title: message.title,
            description: message.description,
            type: message.status,
            duration: 5000,
            isClosable: true,
        });
    }, []);

    return (
        <Box>
            <Toaster />
            <OmcTreeQueryBar
                accessToken={accessToken}
                currentProject={currentProject}
                projects={projects}
                handleProjectChange={handleProjectChange}
                resetRoot={resetRoot}
                displayMessage={displayMessage}
            />
            <Flex direction="column" ref={containerRef} basis={d3ContainerSize.height}>
                <Flex direction="row" maxH={d3ContainerSize.height}>
                    <D3ZoomWrapper
                        treeId={treeId}
                        d3ContainerSize={d3ContainerSize}
                    >
                        <OmcTreeDiagram
                            accessToken={accessToken}
                            containerDimensions={d3ContainerSize}
                            chartData={chartData}
                            projects={projects}
                            currentProject={currentProject}
                            treeId={treeId}
                            setSideBar={setSideBarOmc}
                        />
                    </D3ZoomWrapper>
                    <OmcTreeSideBar
                        omc={sideBarOmc}
                        projects={projects}
                        currentProject={currentProject}
                    />
                </Flex>
            </Flex>
        </Box>
    );
}

export default OmcTreeContainer;
