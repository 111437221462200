/**
 * Main Page Component for displaying a tree based graph of OMC data
 */

import React, { useState, useEffect } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import {
    Center,
    Spinner,
} from '@chakra-ui/react';

import OmcTreeContainer from '../Components/Diagrams/OmcExplore/OmcTreeContainer';
import getIdentifier from '../services/getIdentifier.mjs';
import getEntityType from '../services/getEntity.mjs';

import config from '../config';

const { projects } = config;
const defaultProject = 'europa';

function OmcTree() {
    const {
        authState,
        oktaAuth,
    } = useOktaAuth();
    const [userIdToken, setIdToken] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [omcData, setOmcData] = useState(null);
    const [currentProject, setCurrentProject] = useState(Object.keys(projects)[0]); // Default to the Europa project
    const [resetZoom, setResetZoom] = useState(false);

    useEffect(() => {
        if (!accessToken) return;
        const params = { ...projects[currentProject].root, ...{ currentProject } };
        getIdentifier({ params, accessToken })
            .then((data) => {
                console.log('Got initial entity data');
                console.log(data);
                setOmcData(data);
            });
    }, [accessToken]);

    useEffect(() => {
        const fetchOktaInfo = async () => {
            // const info = await oktaAuth.getUser();
            const authTokens = await oktaAuth.token.getWithoutPrompt();
            setAccessToken(authTokens.tokens.accessToken.accessToken);
            setIdToken(authTokens.tokens.idToken);
        };

        if (!authState.isAuthenticated) {
            setIdToken(null); // When user isn't authenticated, forget any user info
        } else {
            fetchOktaInfo();
        }
    }, [authState, oktaAuth]); // Update if authState changes

    // Called from TreeRootQuery, when the user wants to reset the root node
    const resetRoot = (async (params) => {
        console.log('Reset the root node');
        console.log(params);
        const data = params.entityType
            ? await getEntityType({ params, accessToken })
            : await getIdentifier({ params, accessToken });
        console.log('Got initial entity data');
        console.log(data);
        setOmcData(data);
        setResetZoom(!resetZoom); // Reset the zoom and center the tree
        return data;
    });

    // Change the active project
    function handleProjectChange(projectName) {
        setCurrentProject(projectName);
        console.log(`Change the project to ${projectName}`);
        const params = { ...projects[projectName].root, ...{ currentProject: projectName } };
        resetRoot(params);
    }

    // If the user is not yet authorized return the spinner
    if (!userIdToken && !omcData) {
        return (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        );
    }

    return (
        <OmcTreeContainer
            accessToken={accessToken}
            resetRoot={resetRoot}
            omcData={omcData}
            currentProject={currentProject}
            projects={projects}
            handleProjectChange={(e) => handleProjectChange(e)}
            resetZoom={resetZoom}
        />
    );
}

export default OmcTree;
