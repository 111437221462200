/**
 * Configuration details for API'
 *
 * NO SECRETS
 *
 * oidc - oAuth login for the portal
 * labkoatMessaging - oAuth login for the messaging system
 * labkoatApiName - User friendly name for AWS API gateway
 *
 */

export default {
    oidc: {
        clientId: '0oa452q4cl4epJNGg696',
        // issuer: 'https://movielabs.okta.com/oauth2/default',
        issuer: 'https://movielabs.okta.com/oauth2/aus4zqd8ksuiL13Rl697', // Auth server Labkoat
        redirectUri: `${window.location.origin}/login/callback`,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: 'false',
        idps: [{
            type: 'GOOGLE',
            id: '0oa2rwap2qZg6By9B697',
        }],
        idpDisplay: 'SECONDARY',
    },
    labkoatMessaging: {
        baseUrl: 'https://c2-api.movielabs.com/auth/', // Base URL for authorization flow
        authRoute: 'auth', // Route for exchanging code for JWT
        issuerRoute: 'login?idp=okta', // Route to start oAuth flow
        redirectUri: `${window.location.origin}/openid`,
        source: 'labkoatMsg',
    },
    labkoatApiName: 'labkoatApiDev',
    projects: {
        Europa1: {
            label: 'Europa 1',
            primaryScope: 'etc',
            root: {
                identifierScope: 'etc',
                identifierValue: 'cw-6zs2nlw2YKuNBX4',
            },
        },
        Europa2: {
            label: 'Europa 2',
            primaryScope: 'movielabs.com/omc/europa',
            root: {
                identifierScope: 'movielabs.com/omc/europa',
                identifierValue: 'nsksgVvC1ylGhoq',
            },
        },
        POC6: {
            label: 'HSM',
            primaryScope: 'labkoat',
            root: {
                identifierScope: 'labkoat',
                identifierValue: 'cw/hlDTz5s8IStbxWvdRVfSh',
            },
        },
        RebelFleet: {
            label: 'Rebel Fleet',
            primaryScope: 'movielabs.com/omc/rebelfleet',
            root: {
                identifierScope: 'therebelfleet.co.nz-Europa',
                identifierValue: 'cw-e84f2b8c6cc879b4314b2c83d871599c',
            },
        },
        Yamdu: {
            label: 'Yamdu',
            primaryScope: 'movielabs.com/omc/yamdu',
            root: {
                identifierScope: 'com.yamdu.app',
                identifierValue: 'cw-6zs2nlw2YKuNBX4',
            },
        },
        NBC: {
            label: 'NBC/U',
            primaryScope: 'movielabs.com/omc/nbc',
            root: {
                identifierScope: 'fast8',
                identifierValue: '4:250933a5-fc16-4ecb-96c9-107da5d29f57:898',
            },
        },
        filmustage: {
            label: 'Film-U-Stage',
            primaryScope: 'movielabs.com/omc/filmustage',
            root: {
                identifierScope: 'com.filmustage.app',
                identifierValue: 'com.filmustage.app.scene.bc841ee45ee411efbcd24a81c5642258',
            },
        },
    },
};
