/*
 Request a single OMC entity from the LabKoat API using the provided parameters.
 The function returns the OMC entity as a JavaScript object.
 */

import { OmcModel } from './omcUtil.mjs';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const apiPath = '/api/omc/v1/identifier';

/**
 * Request a single OMC entity from the LabKoat API using the provided parameters.
 * @param params
 * @param params.displayMessage {function} - The function to toast messages in UI
 * @param params.identifierScope {string} - The scope of the identifier
 * @param params.identifierValue {string} - The value of the identifier
 * @param params.currentProject {string} - The current project identifier
 * @param accessToken {string} - The access token for the request
 * @returns {Promise<(*)|null>}
 */

export default async function getIdentifier({ params, accessToken }) {
    const {
        displayMessage = () => {},
        identifierScope,
        identifierValue,
        currentProject: project,
    } = params;

    const iterateMsg = ((msgs, status) => {
        msgs.forEach((msg) => { displayMessage({ title: msg.title, description: msg.details, status }); });
    });

    const url = `${labkoatApiUrl}${apiPath}?project=${project}&identifierScope=${identifierScope}&identifierValue=${identifierValue}`;
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };

    try {
        console.log(url);
        const res = await fetch(url, options);
        const payload = await res.json();

        if (payload.warnings) iterateMsg(payload.warnings, 'warning'); // Display any warnings
        if (payload.errors) iterateMsg(payload.errors, 'error'); // Display any errors

        return res.status === 200 ? OmcModel(payload.data[0]) : null;
    } catch (err) {
        console.log(err);
        return null;
    }
}
